
import { getTestimonials } from '~/graphql/fragments-collection.js'

export default {
  props: {
    data: {
      type: Object,
      default: () => ({}),
    },
  },

  data() {
    return {
      slides: [],
    }
  },

  computed: {
    swiperOptions() {
      return {
        slidesPerView: 1,
        allowTouchMove: this.multipleSlides,
        spaceBetween: 30,
        pagination: {
          el: '.swiper-pagination',
          clickable: true,
        },

        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        },
      }
    },

    correspondingSlides() {
      if (
        this.data.load_automatically ||
        !Array.isArray(this.data.slides) ||
        this.data.slides.length === 0
      ) {
        return this.slides
      }

      return this.data.slides
    },

    multipleSlides() {
      return (
        Array.isArray(this.correspondingSlides) &&
        this.correspondingSlides.length > 1
      )
    },
  },

  watch: {
    data: {
      handler(newData) {
        if (!newData.load_automatically || newData.amount_testimonials === 0) {
          return
        }
        this.getTestimonials()
      },

      deep: true,
      immediate: true,
    },
  },

  methods: {
    async getTestimonials() {
      try {
        const res = await this.$graphql.cms.request(getTestimonials, {
          lang: this.$i18n.locale,
          limit: parseInt(this.data.amount_testimonials || 5),
        })
        this.slides = res.entriesRandom?.data
      } catch (e) {
        console.log('TestimonialSlider/getTestimonials', e)
      }
    },
  },
}
